@media only screen and (max-width: 1024px) {
    .mediaInfo::after {
        content: "1024px";
    }
    .shimmer {
        font: 45px/1.1em opensans-bold, sans-serif;
    }
    #home h1 {
        font-size: 35px;
    }
    #resume h1 {
        font-size: 25px;
    }
}
@media only screen and (max-width: 900px) {
    #reference1 {
        left: 10% !important;
        bottom: 54% !important;
        width: 80% !important;
        font-size: 12px !important;
    }
    #reference1 blockquote p{
        font-size: 15px !important;
        line-height: 18px !important;
    }
    #reference1 {
        margin-bottom: -20px !important;
    }
    #reference2 {
        left: 10% !important;
        top: 50% !important;
        width: 80% !important;
        font-size: 12px !important;
    }
    #reference2 blockquote p{
        font-size: 15px !important;
        line-height: 18px !important;
    }
    blockquote {
        padding-left: 30px;
    }



    .mediaInfo::after {
        content: "900px";
    }
    .shimmer {
        font: 40px/1.1em opensans-bold, sans-serif;
    }
    #home h1 {
        font-size: 35px;
    }
    #nav-wrap {
        font: 11px opensans-bold, sans-serif;
        letter-spacing: 1.5px;
    }
    #home .contact-details {
        width: 50%;
    }
    #home .download {
        width: 50%;
    }
    #resume h1 {
        font: 16px/24px opensans-bold, sans-serif;
    }
    #resume .main-col {
        padding-right: 5%;
    }
    #testimonials .header-col h1:before {
        font-size: 66px;
        line-height: 66px;
    }
    #testimonials blockquote p {
        font-size: 22px;
        line-height: 46px;
    }
    #contact .section-head {
        margin-bottom: 30px;
    }
    #contact .header-col h1:before {
        font-size: 66px;
        line-height: 66px;
    }
    #contact .section-head p.lead {
        font: 17px/33px opensans-light, sans-serif;
    }
    .s-bgrid-quarters [class*="column"] {
        width: 25%;
    }
    .s-bgrid-thirds [class*="column"] {
        width: 33.33333%;
    }
}
@media only screen and (max-width: 767px) {
    #about a {
        float: left;
    }
    #about p {
        font-size: 15px;
    }
    .mediaInfo::after {
        content: "767px";
    }
    .shimmer {
        font: 30px/1.1em opensans-bold, sans-serif;
    }
    #home h1 {
        font-size: 30px;
    }
    #home h3 {
        font-size: 15px;
        margin-bottom: -20px;
    }
    #home h4 {
        font-size: 18px;
    }
    #home i {
        font-size: 24px;
    }
    
/* mobile navigation
   -------------------------------------------------------------------- */
   #nav-wrap {
    font: 12px 'opensans-bold', sans-serif;
    background: transparent !important;
    letter-spacing: 1.5px;
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
}
#nav-wrap > a {
    width: 48px;
    height: 48px;
    text-align: left;
    background-color: #3243dd;
    position: relative;
    border: none;
    float: right;

    font: 0/0 a;
    text-shadow: none;
    color: transparent;

    position: relative;
    top: 0px;
    right: 30px;
}

#nav-wrap > a:before,
#nav-wrap > a:after {
    position: absolute;
    border: 2px solid #fff;
    top: 35%;
    left: 25%;
    right: 25%;
    content: '';
}
#nav-wrap > a:after {
    top: 60%;
}

/* toggle buttons */
#nav-wrap:not(:target) > a:first-of-type,
#nav-wrap:target > a:last-of-type {
    display: block;
}

/* hide menu panel */
#nav-wrap ul#nav {
    height: auto;
    display: none;
    clear: both;
    width: auto;
    float: right;

    position: relative;
    top: 12px;
    right: 0;
}

/* display menu panels */
#nav-wrap:target > ul#nav {
    display: block;
    padding: 30px 20px 48px 20px;
    background: #1f2024;
    margin: 0 30px;
    clear: both;
}

ul#nav li {
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0 4%;
    text-align: left;
    border-bottom: 1px solid #2d2e34;
    border-bottom-style: dotted;
}

ul#nav li a {
    display: block;
    margin: 0;
    padding: 0;
    margin: 12px 0;
    line-height: 16px; /* reset line-height from 48px */
    border: none;
}

    .shimmer {
        font: 40px/1.1em opensans-bold, sans-serif;
    }
    header .social {
        margin: 18px 0 24px 0;
        font-size: 24px;
        line-height: 36px;
    }
    header .social li {
        margin: 0 10px;
    }
    
    #portfolio-wrapper .columns {
        margin-bottom: 40px;
    }
    #testimonials h1 {
        font: 16px/24px opensans-bold, sans-serif;
        text-align: center;
        margin-bottom: 30px;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 1);
    }
    #testimonials h1 span {
        display: block;
    }
    #testimonials .header-col h1:before {
        content: none;
    }
    #testimonials blockquote {
        padding-bottom: 24px;
    }
    #testimonials blockquote p {
        font-size: 20px;
        line-height: 42px;
    }
    #contact {
        padding-bottom: 66px;
    }
    #contact .section-head {
        margin-bottom: 12px;
    }
    #contact .section-head h1 {
        font: 16px/24px opensans-bold, sans-serif;
        text-align: center;
        margin-bottom: 30px;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 1);
    }
    #contact h1 span {
        display: block;
    }
    #contact .header-col {
        padding-top: 0;
    }
    #contact .header-col h1:before {
        content: none;
    }
    #contact .section-head p.lead {
        text-align: center;
    }
    #contact label {
        float: none;
        width: 100%;
    }
    #contact input,
    #contact textarea {
        margin-bottom: 6px;
        width: 100%;
    }
    #contact button.submit {
        margin: 30px 0 24px 0;
    }
    footer .copyright li:before {
        content: none;
    }
    footer .copyright li {
        margin-right: 12px;
    }
    footer .social-links {
        font-size: 22px;
    }
    footer .social-links li {
        margin-left: 18px;
    }
    #go-top {
        margin-left: -22px;
    }
    #go-top a {
        width: 54px;
        height: 54px;
        font-size: 18px;
        line-height: 54px;
    }
    .row {
        width: 460px;
        margin: 0 auto;
        padding: 0;
    }
    .columns {
        width: auto !important;
        float: none;
        margin-left: 0;
        margin-right: 0;
        padding: 0 30px;
    }
    .row .row {
        width: auto;
        max-width: none;
        margin: 0 -30px;
    }
    [class*="column"] + [class*="column"]:last-child {
        float: none;
    }
    [class*="bgrid"] [class*="column"] + [class*="column"]:last-child {
        float: none;
    }
}
@media only screen and (max-width: 480px) {
    .mediaInfo::after {
        content: "480px";
    }
    #nav-wrap ul#nav {
        width: auto;
        float: none;
    }
    .shimmer {
        font: 30px/1.1em opensans-bold, sans-serif;
    }
    #about p {
        font-size: 12px;
    }
    #about i {
        font-size: 12px !important;
    }
    .button{
        font-size: 10px;
        text-align: center;
    }
    #home h1 {
        font-size: 25px;
    }
    #home h3 {
        font-size: 15px;
        margin-bottom: -40px;
    }
    #home h4 {
        font-size: 18px;
    }
    #home i {
        font-size: 24px;
    }
    .row {
        width: auto;
    }
    header .social {
        font-size: 20px;
    }
    header .social li {
        margin: 0 6px;
    }
    footer .social-links {
        font-size: 20px;
    }
    footer .social-links li {
        margin-left: 14px;
    }
}
