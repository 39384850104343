
address,
aside,
blockquote,
body,
cite,
details,
em,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
label,
li,
nav,
p,
q,
section,
span,
ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0;
    overflow: hidden;
}
aside,
details,
footer,
header,
nav,
section {
    display: block;
}
[hidden] {
    display: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none;
}
*,
:after,
:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
}
body {
    background: #fff;
    font-family: opensans-regular, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #838c95;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #313131;
    font-family: opensans-bold, sans-serif;
    font-weight: 400;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: inherit;
}
h1 {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 12px;
    letter-spacing: -1px;
}
h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 12px;
}
h4 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 6px;
}
h5 {
    font-size: 18px;
    line-height: 30px;
}
h6 {
    font-size: 14px;
    line-height: 30px;
}
p {
    margin: 0 0 30px 0;
}
p img {
    margin: 0;
}
p.lead {
    font: 19px/36px opensans-light, sans-serif;
    margin-bottom: 18px;
}
em {
    font: 15px/30px opensans-italic, sans-serif;
}
blockquote {
    margin: 5px 0;
    padding-left: 40px;
    position: relative;
}
blockquote:before {
    content: "\201C";
    opacity: 0.45;
    font-size: 80px;
    line-height: 0;
    margin: 0;
    font-family: arial, sans-serif;
    position: absolute;
    top: 30px;
    left: 0;
}
blockquote p {
    font-family: librebaskerville-italic, serif;
    padding: 0;
    font-size: 18px;
    line-height: 36px;
}
blockquote cite {
    display: block;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
}
blockquote cite:before {
    content: "\2014 \0020";
}
blockquote cite a,
blockquote cite a:visited {
    color: #8b9798;
    border: none;
}
hr {
    border: solid #e3e3e3;
    border-width: 1px 0 0;
    clear: both;
    margin: 11px 0 30px;
    height: 0;
}
a,
a:visited {
    text-decoration: none;
    outline: 0;
    color: #9d4cf3;
    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;
}
a:focus,
a:hover {
    color: #313131;
}
p a,
p a:visited {
    line-height: inherit;
}
ul {
    margin-bottom: 24px;
    margin-top: 12px;
}
ul {
    list-style: none outside;
}
ul.circle {
    margin-left: 30px;
}
ul.circle {
    list-style: circle outside;
}
ul ul {
    margin: 6px 0 6px 30px;
}
ul ul li {
    margin-bottom: 6px;
}
li {
    line-height: 18px;
    margin-bottom: 12px;
}
img {
    max-width: 100%;
    height: auto;
}
.button,
.button:visited,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    font: 16px/30px opensans-bold, sans-serif;
    background: #1815ce;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 0;
    color: #fff;
    padding: 12px 20px;
    margin-bottom: 18px;
    border: none;
    cursor: pointer;
    height: auto;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
}
.button:hover,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
    background: #3d4145;
    color: #fff;
}
.button:active,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
    background: #3d4145;
    color: #fff;
}
.button.full-width,
button.full-width,
input[type="button"].full-width,
input[type="reset"].full-width,
input[type="submit"].full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
form {
    margin-bottom: 24px;
}
input[type="email"],
input[type="password"],
input[type="text"],
textarea {
    display: block;
    padding: 18px 15px;
    margin: 0 0 24px 0;
    border: 0;
    outline: 0;
    min-width: 225px;
    max-width: 100%;
    font-size: 15px;
    line-height: 24px;
    color: #647373;
    background: #d3d9d9;
}
input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus,
textarea:focus {
    color: #b3b7bc;
    background-color: #3d4145;
}
textarea {
    min-height: 220px;
}
label {
    font: 16px/24px opensans-bold, sans-serif;
    margin: 12px 0;
    color: #3d4145;
    display: block;
}
label span {
    color: #8b9798;
    font: 14px/24px opensans-regular, sans-serif;
}
input[type="checkbox"],
input[type="radio"] {
    font-size: 15px;
    color: #737373;
}
input[type="checkbox"] {
    display: inline;
}
.row {
    width: 96%;
    max-width: 1020px;
    margin: 0 auto;
}
.row .row {
    width: auto;
    max-width: none;
    margin: 0 -20px;
}
.row:after,
.row:before {
    content: " ";
    display: table;
}
.row:after {
    clear: both;
}
.columns {
    position: relative;
    padding: 0 20px;
    min-height: 1px;
    float: left;
}
.columns.collapsed,
.row.collapsed > .columns {
    padding: 0;
}
[class*="column"] + [class*="column"]:last-child {
    float: right;
}
.row .three {
    width: 25%;
}
.row .four {
    width: 33.33333%;
}
.row .eight {
    width: 66.66667%;
}
.row .nine {
    width: 75%;
}
.row .ten {
    width: 83.33333%;
}
.row .twelve {
    width: 100%;
}
.bgrid-quarters [class*="column"] {
    width: 25%;
}
.bgrid-thirds [class*="column"] {
    width: 33.33333%;
}
[class*="bgrid"] [class*="column"] + [class*="column"]:last-child {
    float: left;
}


.mediaInfo {
    color: black;
    background-color: yellow;
}
.mediaInfo::after {
    content: "FULL";
}

.glow {
    text-shadow: 1px 1px 2px rgb(29, 10, 197), 0 0 10px blue, 0 0 5px rgb(17, 17, 218);
  }


/*
  ---------------------------------------------------
  Animations for slide in left, slide out right
  ---------------------------------------------------
  */

  .drop-appear,
  .drop-enter {
      opacity: 0;
      transform: translateY(-250px);
  }
  .drop-appear.drop-appear-active,
  .drop-enter.drop-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: 0.5s;
  }
  
  .drop-leave {
      opacity: 1;
      transform: translateY(0);
  }
  
  .drop-leave.drop-leave-active {
      opacity: 0;
      transform: translateY(-250px);
      transition: 0.5s;
  }

  .slide-appear,
  .slide-enter {
      opacity: 0;
      transform: translateX(-250px);
  }
  .slide-appear.slide-appear-active,
  .slide-enter.slide-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: 0.5s;
  }
  
  .slide-leave {
      opacity: 1;
      transform: translateX(0);
  }
  
  .slide-leave.slide-leave-active {
      opacity: 0;
      transform: translateX(250px);
      transition: 0.5s;
  }
  
  .spicy-appear,
  .spicy-enter {
      opacity: 0.01;
      transform: scaleX(0) translateY(100px);
  }
  
  .spicy-enter.spicy-enter-active {
      opacity: 1;
      transform: scaleX(1) translateY(0);
      transition: all 500ms ease-in-out;
  }
  
  .spicy-leave {
      opacity: 1;
      transform: scaleX(1) translateY(0);
  }
  
  .spicy-leave-active {
      opacity: 01;
      transform: scaleX(0) translateY(100px);
      transition: all 300ms ease-in-out;
  }
  
@-webkit-keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   @-moz-keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   @-o-keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   @keyframes cf4FadeInOut {
    0% {
      opacity:1;
    }
    17% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    92% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
   }
   
   #cf4a {
     position:relative;
     height:281px;
     width:450px;
     margin:0 auto;
   }
   #cf4a img {
     position:absolute;
     left:0;
   }
   
   #cf4a img {
     -webkit-animation-name: cf4FadeInOut;
     -webkit-animation-timing-function: ease-in-out;
     -webkit-animation-iteration-count: infinite;
     -webkit-animation-duration: 8s;
   
     -moz-animation-name: cf4FadeInOut;
     -moz-animation-timing-function: ease-in-out;
     -moz-animation-iteration-count: infinite;
     -moz-animation-duration: 8s;
   
     -o-animation-name: cf4FadeInOut;
     -o-animation-timing-function: ease-in-out;
     -o-animation-iteration-count: infinite;
     -o-animation-duration: 8s;
   
     animation-name: cf4FadeInOut;
     animation-timing-function: ease-in-out;
     animation-iteration-count: infinite;
     animation-duration: 8s;
   }
   #cf4a img:nth-of-type(1) {
     -webkit-animation-delay: 4s;
     -moz-animation-delay: 4s;
     -o-animation-delay: 4s;
     animation-delay: 4s;
   }
   #cf4a img:nth-of-type(2) {
     -webkit-animation-delay: 2s;
     -moz-animation-delay: 2s;
     -o-animation-delay: 2s;
     animation-delay: 2s;
   }
   #cf4a img:nth-of-type(3) {
     -webkit-animation-delay: 0;
     -moz-animation-delay: 0;
     -o-animation-delay: 0;
     animation-delay: 0;
   }
   

   ::-webkit-scrollbar {
    width: 15px;
    height: 80%;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: rgb(29, 8, 221);
    border-radius: 10px;
    box-shadow: inset 0 0 15px rgb(2, 0, 19);
}
::-webkit-scrollbar-thumb:hover {
    background: #fff;
    box-shadow: inset 0 0 15px rgb(28, 3, 247);
}

.cf:after,
.cf:before {
    content: " ";
    display: table;
}
.cf:after {
    clear: both;
}
.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
ul#nav li p:active {
    background-color: transparent !important;
}
ul#nav li.current a {
    color: #00f;
    font-weight: bold;
}
body {
    background: #000;
}
.shimmer {
    font: 50px/1.1em opensans-bold, sans-serif;
    background: -webkit-gradient(linear, left top, right top, from(rgb(14, 33, 199)), to(rgb(14, 33, 199)), color-stop(.75, rgb(255, 255, 255)));
    background: -moz-gradient(linear, left top, right top, from(rgb(14, 33, 199)), to(rgb(14, 33, 199)), color-stop(.75, rgb(255, 255, 255)));
    background: gradient(linear, left top, right top, from(rgb(14, 33, 199)), to(rgb(14, 33, 199)), color-stop(.75, rgb(255, 255, 255)));
    -webkit-background-size: 125px 100px;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-name: shimmer;
    -moz-animation-name: shimmer;
    -webkit-animation-name: shimmer;
    animation-name: shimmer;
    -webkit-animation-duration: 4s;
    -moz-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: rgb(26, 2, 241);
}
@-moz-keyframes shimmer {
  0% {
    background-position: -200px 0px;
}
100% {
    background-position: 1100px 0px;
}
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -200px 0px;
}
100% {
    background-position: 1100px 0px;
}
}
@-o-keyframes shimmer {
  0% {
    background-position: -200px 0px;
}
100% {
    background-position: 1100px 0px;
}
}
@-ms-keyframes shimmer {
  0% {
      background-position: -200px 0px;
  }
  100% {
      background-position: 1100px 0px;
  }
}
@keyframes shimmer {
  0% {
      background-position: -200px 0px;
  }
  100% {
      background-position: 1100px 0px;
  }
}

header:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}
header .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}
.social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
    font-size: 30px;
}
.social li a:hover {
    color: #1815d3 !important;
}
#nav-wrap a,
#nav-wrap li,
#nav-wrap ul {
    margin: 0;
    padding: 0;
    border: none;
    outline: 0;
}
#nav-wrap {
    font: 15px opensans-bold, sans-serif;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin: 0 auto;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
}
#nav-wrap > a.mobile-btn {
    display: none;
}
ul#nav {
    min-height: 48px;
    width: auto;
    text-align: center;
}
ul#nav li {
    position: relative;
    list-style: none;
    height: 48px;
    display: inline-block;
}
ul#nav li a {
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;
    color: #fff;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}
#home {
    background: #2b2b2b;
    padding-top: 96px;
    padding-bottom: 66px;
    overflow: hidden;
}
#home a,
#home a:visited {
    color: #fff;
}
#home h1 {
    font-size: 38px;
    color: #fff;
}
#home h3 {
    color: #fff;
    font-size: 18px;
}
#home h4 {
    color: #fff;
    font-size: 26px;
}
#home h2 {
    color: rgba(36, 26, 173, 0.2);
}
#home p {
    line-height: 30px;
    color: #fff;
}
#home .contact-details {
    width: 41.66667%;
}

#about p {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
}
#about {
    font-size: 20px;
}
#about a {
    float: right;
}
#about .download .button:hover {
    background: #fff;
    color: #2012e0;
}
#about .download .button i {
    margin-right: 15px;
    font-size: 20px;
}

#resume h1 {
    font: 25px/24px opensans-bold, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 5px;
    margin-bottom: 30px;
}
#resume h1 span {
    border-bottom: 3px solid #1725db;
    color: #fff;
}
#resume h3 {
    font: 25px/30px opensans-bold, sans-serif;
    color: #fff;
}
#resume h4 {
    color: #fff;
}
#works p{
    line-height: 18px;
}

.education,
.work {
    background: rgb(255, 255, 255, 0);
    margin-bottom: 48px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;
    margin-top: 30px;
    color: #cfd4d8;
}
#resume .info {
    font: 18px/24px librebaskerville-italic, serif;
    color: #999ff5;
    margin-bottom: 18px;
    margin-top: 9px;
}
#resume .info span {
    margin-right: 5px;
    margin-left: 5px;
}
#resume .date {
    font: 15px/24px opensans-regular, sans-serif;
    margin-top: 6px;
}
#portfolio {
    background: #3b3f3f;
    padding-top: 90px;
    padding-bottom: 60px;
    text-align: center;
}
#portfolio h1 {
    font: 15px/24px opensans-semibold, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 48px;
    color: #f2ecf7;
}
#portfolio-wrapper .columns {
    margin-bottom: 36px;
}
.portfolio-item .item-wrap {
    background: rgba(0, 0, 0, 0);
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.portfolio-item .item-wrap a {
    display: block;
    cursor: pointer;
}
.portfolio-item .item-wrap img {
    vertical-align: middle;
    max-height: 170px;
    max-width: 230px;
    width: 100%;
}
.portfolio-item .portfolio-item-meta {
    padding-top: 18px;
}
.portfolio-item .portfolio-item-meta h5 {
    font: 16px/21px opensans-bold, sans-serif;
    color: #fff;
}
.portfolio-item .portfolio-item-meta p {
    font: 14px/18px opensans, sans-serif;
    color: #fff;
    margin-top: -20px;
}
.portfolio-item:hover .overlay {
    opacity: 1;
    -moz-opacity: 1;
}


#contact {
    background: #191919;
    padding-top: 96px;
    padding-bottom: 102px;
    color: #d9d2db;
}
#contact .section-head {
    margin-bottom: 42px;
}
#contact a,
#contact a:visited {
    color: #1a17f0;
}
#contact a:focus,
#contact a:hover {
    color: #fff;
}
#contact h1 {
    font: 18px/24px opensans-bold, sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ebeeee;
    margin-bottom: 6px;
}
#contact h1 span {
    display: none;
}
#contact h1:before {
    font-family: FontAwesome;
    content: "\f0e0";
    padding-right: 10px;
    font-size: 72px;
    line-height: 72px;
    text-align: left;
    float: left;
    color: #ebeeee;
}
#contact h3 {
    color: #fb0000;
    margin-left: 26%;
}
#contact h4 {
    font: 16px/24px opensans-bold, sans-serif;
    color: #ebeeee;
    margin-bottom: 6px;
}
#contact p.lead {
    font: 18px/36px opensans-light, sans-serif;
    padding-right: 3%;
}
#contact .header-col {
    padding-top: 6px;
}
#contact form {
    margin-bottom: 30px;
}
#contact label {
    font: 15px/24px opensans-bold, sans-serif;
    margin: 12px 0;
    color: #ebeeee;
    display: inline-block;
    width: 26%;
}
#contact input,
#contact textarea {
    padding: 18px 20px;
    color: #eee;
    background: #373233;
    margin-bottom: 42px;
    border: 0;
    outline: 0;
    font-size: 15px;
    line-height: 24px;
    width: 65%;
}
#contact input:focus,
#contact textarea:focus {
    color: #fff;
    background-color: #1326d8;
}
#contact button.submit {
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    background: #0d0d0d;
    border: none;
    cursor: pointer;
    height: auto;
    display: inline-block;
    border-radius: 3px;
    margin-left: 26%;
}
#contact button.submit:hover {
    color: #0d0d0d;
    background: #fff;
}
span.required {
    color: #fff;
    font-size: 13px;
}
.addressHeader {
    color: #fff;
    font-size: 22px;
}
#image-loader {
    display: none;
    position: relative;
    left: 18px;
    top: 17px;
}
#image-loader-show {
    display: inline;
    position: relative;
    left: 18px;
    top: 17px;
}
footer {
    padding-top: 48px;
    margin-bottom: 48px;
    color: #d3d2d8;
    font-size: 14px;
    text-align: center;
    position: relative;
}
footer a,
footer a:visited {
    color: #dad7dd;
}
footer a:focus,
footer a:hover {
    color: #fff;
}
footer .copyright {
    margin: 0;
    padding: 0;
}
footer .copyright li {
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: 24px;
}
footer .copyright li:before {
    content: "\2022";
    padding-left: 10px;
    padding-right: 10px;
    color: #9d4cf3;
}
footer .copyright li:first-child:before {
    display: none;
}
footer .social-links {
    margin: 18px 0 30px 0;
    padding: 0;
    font-size: 30px;
}
footer .social-links li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 42px;
    color: #f06000;
}
footer .social-links li:first-child {
    margin-left: 0;
}
#go-top {
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -30px;
}
#go-top a {
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 60px;
    height: 60px;
    background-color: #525252;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #fff;
    font-size: 21px;
    line-height: 60px;
    border-radius: 100%;
}
#go-top a:hover {
    background-color: #0b08d6;
}
